/* eslint-disable camelcase */
import axios from 'axios';
import { ga } from 'react-ga';

import { ExistingContactHubspotParamsTypes, GetContactByEmailHubSpotTypes, HubSpotToGaParamsTypes } from './types';

import { getTokenSession } from 'services/common/storage';

const HUBSPOT_API_URL = 'https://api.hubapi.com/contacts/v1/contact/';
export type HubSpotParamsFormTypes = {
  firstname: string;
  middlename: string;
  lastname: string;
  email: string;
  phone: string;
  terms_and_conditions: boolean;
  oreo_opt_in: boolean;
  cid: string;
}

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const submitHubspotForm = async (data: HubSpotParamsFormTypes) => {
  const portalId = '19533831';
  const formGuid = '7c5391b5-2ba4-45e9-bdb8-9aa18cce9fce';

  const response = await axios.post(`https://legacydocs.hubspot.com/docs/methods/forms/submit_form/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields: [
        {
          name: 'firstname',
          value: data.firstname,
        },
        {
          name: 'middlename',
          value: data.middlename,
        },
        {
          name: 'lastname',
          value: data.lastname,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'terms_and_conditions',
          value: data.terms_and_conditions,
        },
        {
          name: 'oreo_opt_in',
          value: data.oreo_opt_in,
        },
        {
          name: 'cid',
          value: data.cid,
        },
      ],
    },
    config);
  return response;
};

/**
 * Get contact ID hubspot by Email
 * @param email
 * @returns
 */

export const getContactByEmailHubspot = async (
  email: string,
  hapikey: string,
): Promise<GetContactByEmailHubSpotTypes | null> => {
  const token = getTokenSession();
  if (!token) return null;
  const response = await axios.get(`${HUBSPOT_API_URL}email/${email}/profile?hapikey=${hapikey}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  });
  return response.data;
};

/**
 * Push HubSpot contact id to Google Analytics
 * @param params HubSpotToGaParamsTypes
 */
export const pushHubspotToGa = async (params: HubSpotToGaParamsTypes) => {
  let clientId;
  ga((tracker: any) => {
    clientId = tracker.get('clientId');
  });
  const res = await axios.post(`
  https://www.google-analytics.com/collect?v=1&t=event&tid=${params.gaId}&cid=${clientId}&ec=measurement+protocol&ea=crm+data+injection&el=lead-updated&cd2=${params.contactId}
  `, config);
  return res.data;
};

/**
 * Update an existing contact
 * @param params: ExistingContactHubspotParamsTypes
 * @param vid: string
 * @returns
 */
export const updateExistingContactHubspot = async (
  params: ExistingContactHubspotParamsTypes, vid: string, hapikey: string,
) => {
  const token = getTokenSession();
  if (!token) return null;
  const res = await axios.post(`${HUBSPOT_API_URL}vid/${vid}/profile?hapikey=${hapikey}`, params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  });
  return res.data;
};

export default submitHubspotForm;
