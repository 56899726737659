import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SLUG_PARAMS, { LOCAL_PARAMS } from './constants';

import { logoutPgService } from 'services/auth';
import { clearTokenSession, clearValueSession, getTokenSession } from 'services/common/storage';
import { updateFlowAction } from 'store/System';
import { useAppDispatch } from 'store/hooks';

const RedirectToPage: React.FC = ({ children }) => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const token = getTokenSession();

  useEffect(() => {
    const logout = async () => {
      await logoutPgService();
    };
    if (token && location.pathname === '/logout') {
      logout().then(() => {
        navigation(SLUG_PARAMS.LOGIN_EC);
        clearTokenSession();
        clearValueSession(LOCAL_PARAMS.UUID);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname]);

  // useEffect(() => {
  //   if (token) navigation(SLUG_PARAMS.CUSTOMER_INFO);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      navigation(SLUG_PARAMS.LOGIN_EC);
    }
    if (location.pathname === SLUG_PARAMS.LOGIN_EC) {
      dispatch(updateFlowAction('EC'));
    }
    // if (location.pathname === SLUG_PARAMS.LOGIN_PG) {
    //   dispatch(updateFlowAction('PG'));
    // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
};

export default RedirectToPage;
