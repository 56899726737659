import {
  CreateCustomerParamsTypes,
  CreateCustomerResponseTypes,
  CustomerInfomationResponseTypes,
  LoginPgParamsTypes, LoginPgResponseTypes, PhoneInformationTypes, ProfilePgTypes,
} from './type';

import axiosInstance from 'services/common/instance';

const loginPgService = async (params: LoginPgParamsTypes): Promise<LoginPgResponseTypes> => {
  const res = await axiosInstance.post('pg/login', params);
  return res.data.data;
};

export const logoutPgService = async () => {
  const res = await axiosInstance.post('pg/logout');
  return res.data;
};

export const getProfilePgService = async (): Promise<ProfilePgTypes> => {
  const res = await axiosInstance.get('pg/profile');
  return res.data.data;
};

export const getPhoneInformationService = async (
  params: string,
): Promise<PhoneInformationTypes> => {
  const res = await axiosInstance.get(`pg/${params}/information`);
  return res.data.data;
};

export const createCustomerService = async (
  params: CreateCustomerParamsTypes,
): Promise<CreateCustomerResponseTypes> => {
  const res = await axiosInstance.post('customer/create-customer', params);
  return res.data.data;
};

export const getCustomerInformationService = async (
  params: string,
): Promise<CustomerInfomationResponseTypes> => {
  const res = await axiosInstance.get(`customer/${params}/information`);
  return res.data.data;
};
export default loginPgService;
