import React from 'react';

import icHand from 'assets/icons/ic-hand.png';
import logoImg from 'assets/images/logo.png';
import mdlzLogo from 'assets/images/mdlz-logo.png';
import Container from 'components/organisms/Container';
import { handleOpenPrivacyPopup } from 'store/Common';
import { useAppDispatch } from 'store/hooks';

interface MainLayoutProps {
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  return (
    <main className="wrapper">
      <header className="t-mainLayout_header">
        <div className="t-mainLayout_smallLogo">
          <img src={mdlzLogo} alt="mdlz logo" />
        </div>
        <div className="t-mainLayout_logo">
          <img src={logoImg} alt="logo" />
        </div>
      </header>
      <div className="t-mainLayout_body">
        <Container>
          {children}
        </Container>
      </div>
      <footer className="t-mainLayout_footer">
        <Container>
          <div className="t-mainLayout_footer_copyright">
            <p>© Copyright 2022 Mondelēz International </p>
            <div className="link">
              <p
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch(handleOpenPrivacyPopup(true))}
              >
                Chính sách bảo mật

              </p>
              <a
                href="https://www.mondelezinternational.com/Terms-of-Use"
                target="_blank"
                rel="noreferrer"
              >
                Điều khoản sử dụng

              </a>
              <a href="https://contactus.mdlzapps.com/form?siteId=aRZUCOJ%2bRgqdD73c%2bizBrw7sj76YhLj5" target="_blank" rel="noreferrer">Liên hệ</a>
            </div>
          </div>
          <img src={icHand} alt="hand" />
        </Container>
      </footer>
    </main>
  );
};

export default MainLayout;
