const SLUG_PARAMS = {
  CONGRATULATION: '/chuc-mung',
  LOGIN_PG: '/dang-nhap-pg',
  LOGIN_EC: '/dang-nhap-ec',
  CUSTOMER_INFO: '/thong-tin-khach-hang',
  ENDING: '/ket-thuc',
};

export const BASE_URL = 'https://cosy.3forcom.net';
export const GA_ID = 'UA-154849887-12';
export const GTM_ID = 'GTM-PF6SGXM';
export const LOCAL_PARAMS = {
  UUID: 'uuid',
  SUPERMARKET_VALUE: 'supermarket-value',
  CREATED_STATUS: 'created-status',
};

export const HUBSPOT_API_KEY = '2066b35f-a5d7-4f7e-95ae-f7a46067d990';

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const TEMP_TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMDNkMTc4N2NhOGQzODlkODE4MDliNDU1NDUyZTE4ZjJkNDA0MGY0ZTUxMDliNTc5OGE1Mjk4OTc4NDE1NTkyZjAyZmY0N2ExY2E5OWY1MjciLCJpYXQiOjE2NTExMTkwODgsIm5iZiI6MTY1MTExOTA4OCwiZXhwIjoxNjgyNjU1MDg4LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.bWgl36hEZMrWiO4FwOuuO0tJNgmxm3wogVUxZ4F9xTRWMSyeKnNFA6NZeozR7kRZyoE-BVsAW3YtTCOpRk1XqINgmZQtKF-d3pD1jZFfSad-7qrroOyd2GpOmYYwZ0cpjTOyzv8W7V2X5G4CbTT09IIEhzQgPp8volGgaCiKP4qrXb6xaAnlkdFTV-43zMdqjSJSL9ZM42HTRWMxgKk_sSI_iwafFMNoX-3fC52VOCDigmtsxBLw3CdQ52usE5USncdJaxsMXNJtyH2cSOcdpr1_92jowqjZE_oRKtUt07bqVwlfqWHXQWlE_xnOippQddtl5k0tYnKUM_95OEOQ_inH4PEhQpF9d_hFEzvuOqrsjxOnwD3FzwTtjVajctQ9dUH8T0BdX700KgYuwFk39C9VEHqE9lKQlzUI0SLGrZuBjVFXJexkq4Ifh0UTSCFIwGhTOVJfiPzA8IicrF4_6HQOpp_cHmI6tUSDsv7sRTjYw_yH3x38AQyoX13MW913WH_KZyh6vyqdhHmPH25HyLR5NnKDK_T0lRLiaME7GXJYRaCAkhu3ocSvFlqu39FH2ghrCkSoIDdjPN3cAUnbAs65P6pX290OR2w1lq-dmRDCDNrBV4PruFbvLZJoWNbPwHzxL2egvMNczFl5a3XKVY6B3tePB5lB66PXn_2BeHY';

export default SLUG_PARAMS;
