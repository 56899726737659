import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import imgGift10k from 'assets/images/gift-10k.png';
import imgGift20k from 'assets/images/gift-20k.png';
import imgVoucher20k from 'assets/images/voucher-20k.png';
import Congratulation from 'components/templates/Congratulation';
import { getLocalStorage } from 'services/common/storage';
import { getPrizeInfomation } from 'services/customer';
import { FlowType } from 'store/System/type';
import SLUG_PARAMS, { LOCAL_PARAMS } from 'utils/constants';

type GiftCode = 'PHONE_CARD_20K' | 'PHONE_CARD_10k' | 'VOUCHER_20K';

const returnGift = (key: GiftCode) => {
  switch (key) {
    case 'PHONE_CARD_10k':
      return imgGift10k;
    case 'PHONE_CARD_20K':
      return imgGift20k;
    case 'VOUCHER_20K':
      return imgVoucher20k;
    default:
      return '';
  }
};

const CongratulationContainer: React.FC = () => {
  const flowType = getLocalStorage('flow') as FlowType;
  const navigate = useNavigate();
  const hasCreated = getLocalStorage(LOCAL_PARAMS.CREATED_STATUS);
  const uuid = getLocalStorage(LOCAL_PARAMS.UUID);
  const [prizeImg, setPrizeImg] = useState<string>();
  const [errorPrize, setErrorPrize] = useState<string>();
  useEffect(() => {
    if (Number(hasCreated) !== 1) {
      navigate(SLUG_PARAMS.LOGIN_EC);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreated]);

  useEffect(() => {
    if (!uuid) return;
    (async () => {
      const res = await getPrizeInfomation(uuid);
      if (!res.prize.prizeCustomerHas) {
        setErrorPrize(res.prize.prizeMsg);
      }

      if (res.prize.code) {
        const parse = returnGift(res.prize.code as GiftCode);
        setPrizeImg(parse);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Congratulation
      flowType={flowType || 'EC'}
      giftImg={prizeImg}
      messageError={errorPrize}
      handleClick={() => navigate(SLUG_PARAMS.CUSTOMER_INFO)}
    />
  );
};

export default CongratulationContainer;
