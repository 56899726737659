import React from 'react';

import Popup from 'components/organisms/Popup';

interface ConditionalPopupProps {
  isOpen: boolean;
  handleClose?: () => void;
}

const ConditionalPopup: React.FC<ConditionalPopupProps> = ({ isOpen, handleClose }) => (
  <div className="t-policyPopup">
    <Popup
      title="THÔNG BÁO VỀ ĐIỀU KIỆN ĐIỀU KHOẢN"
      isOpenModal={isOpen}
      modifiers="policy"
      handleClose={handleClose}
      hasCloseButton
    >
      <div className="t-policyPopup_line">
        <h3>Giới thiệu</h3>
        <p>
          Tại Mondelēz International, chúng tôi đánh giá cao
          sự tin tưởng của quý vị khi chia sẻ Dữ liệu Cá nhân của quý vị
          với chúng tôi và nhận ra rằng quý vị có thể quan tâm tới thông
          tin đã ung cấp cho chúng tôi và về cách chúng tôi xử lý thông tin đó.
        </p>
        <p>
          Nếu quý vị đang đọc Thông báo về quyền riêng tư này
          {' '}
          <strong>
            (&ldquo;Thông báo về quyền riêng tư&ldquo;)
          </strong>
          {' '}
          thì có thể là do quý vị
          đã truy cập trang web, ứng dụng di động, hồ sơ truyền thông xã hội,
          bất kỳ dịch vụ trực tuyến nào hoặc tương tác với
          chúng tôi tại các điểm hội họp hoặc thông qua các hoạt động
          (tất cả chúng đều được giới thiệu dưới đây là các “trang web”)
          được điều hành bởi bất kỳ thực thể nào của tập đoàn Mondelēz International:
          bao gồm
          {' '}
          <strong>
            Công ty Cổ phần Mondelez Kinh Đô Việt Nam (“MDLZ” hoặc “chúng tôi”)
          </strong>
          {' '}
          hoặc, bởi vì quý vị có thể đã liên hệ trực tiếp với chúng tôi qua điện thoại
          hoặc bằng văn bản.
        </p>
        <p>
          Thông báo về quyền riêng tư này nêu ra cơ sở chúng tôi sử dụng,
          lưu trữ và xử lý Dữ liệu Cá nhân của quý vị
          {' '}
          <strong>(&ldquo;Dữ liệu cá nhân&ldquo;)</strong>
          {' '}
          khi quý vị truy cập các website hoặc liên hệ với chúng tôi.
        </p>
      </div>
    </Popup>
  </div>
);

export default ConditionalPopup;
