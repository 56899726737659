import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommonState {
  openPrivacy?: boolean
}

const initialState: CommonState = {
  openPrivacy: false,
};

const commonSlice = createSlice({
  initialState,
  name: 'commonReducer',
  reducers: {
    handleOpenPrivacyPopup($state, action: PayloadAction<boolean>) {
      $state.openPrivacy = action.payload;
    },
  },
});

export const { handleOpenPrivacyPopup } = commonSlice.actions;

export default commonSlice.reducer;
