import React, { useEffect, useState } from 'react';
import ReactGA, { ga } from 'react-ga';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

import { GA_ID, GTM_ID } from 'utils/constants';

const AppInitializer: React.FC = () => {
  const location = useLocation();
  const [initializedGA, setInitializedGA] = useState(false);

  //* GTM-GA
  useEffect(() => {
    ReactGA.initialize(GA_ID);
    // ga((tracker:any) => {
    //   const clientId = tracker.get('clientId');
    // });
    setInitializedGA(true);

    const GA_LOCAL_STORAGE_KEY = 'ga:clientId';

    if (window.localStorage) {
      ga('create', 'UA-XXXXX-Y', {
        storage: 'none',
        clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY),
      });
      ga((tracker:any) => {
        localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
      });
    } else {
      ga('create', 'UA-XXXXX-Y', 'auto');
    }

    ga('send', 'pageview');

    TagManager.initialize({
      gtmId: GTM_ID,
    });
  }, []);

  useEffect(() => {
    if (initializedGA && location) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initializedGA, location]);

  return null;
};

export default AppInitializer;
